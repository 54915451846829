import * as React from "react"
import { Layout } from '@components'

const NotFoundPage = () => (
  <Layout>
    <h1>dang</h1>
  </Layout>
)

export default NotFoundPage
